import {ref, reactive, toRefs, defineComponent,onActivated, onBeforeMount,onMounted,getCurrentInstance} from 'vue';
import UpdateInfoUtil ,{IUpdateInfoDataObj} from './updateInfoUtil';
export default defineComponent({
    name:'updateInfo',
    beforeRouteEnter(to,from,next){
        if(sessionStorage.getItem('userInfo')){
            next();
        }else{
            next('/clientLogin');
        }
    },
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IUpdateInfoDataObj=reactive<IUpdateInfoDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            form:{},
            suffixIcon:'iconfont icon-mima'
        })
        onActivated(()=>{
            if(sessionStorage.getItem('userInfo')){
                let userInfo=JSON.parse(sessionStorage.getItem('userInfo') as string);
                dataObj.form.code=userInfo.code;
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new UpdateInfoUtil(proxy,dataObj);
        })
        onMounted(()=>{})
        const backHandler=async ()=>{
            await proxy.$router.replace({path:'/clientPersonCenter'});
        }
        const sureHandler=async ()=>{
            if(!dataObj.form.pwd){
                proxy.$message('请输入原密码');
                return;
            }
            let reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
            if(!dataObj.form.newPwd){
                proxy.$message('请输入新密码');
                return;
            }else if(dataObj.form.newPwd.length<6 || dataObj.form.newPwd.length>9 || !reg.test(dataObj.form.newPwd)){
                proxy.$message('新密码规则【6-9位数，要求格式：字母+数字组合】');
                return;
            }
            if(!dataObj.form.surePwd){
                proxy.$message('请输入确认密码');
                return;
            }
            if(dataObj.form.newPwd!=dataObj.form.surePwd){
                proxy.$message('新密码和确认密码不一致');
                return;
            }
            await dataObj.utilInst.updateInfo();
        }
        return{
            ...toRefs(dataObj),backHandler,sureHandler

        }
    }
});